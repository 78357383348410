import styled, { css } from 'styled-components';
import { ShadowedBox } from '../ui.styles'
import { colors as c } from '../colors';

const Section = styled.section`
  margin-bottom: 70px;
`;

export const Intro = styled(Section)`

`;

export const BlurbBox = styled.div`
  ${ShadowedBox};
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

export const BlurbText = styled.div`
  flex-basis: 100%;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    flex-basis: 50%;
    padding-right: 50px;
  }

  p {
    font-size: 2rem;
    line-height: 130%;
    letter-spacing: .5px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
`;

export const Social = styled.p`
  display: flex;
`;

export const SocialIcon = styled.a`
  display: flex;
  text-align: center;
  padding: 10px;
  background-color: transparent;
  font-size: 50px;
  color: ${c.bluePrimary};

  i {
    padding: 0;
  }
`;

const FormInput = css`
  display: block;
  width: 100%;
  font-family: NunitoSans;
  font-size: 2.2rem;
  padding: 1rem;
  border-radius: 4px;
  border: 2px solid transparent;

  ${({ err }) => err && `
    border: 2px solid ${c.redError};
  `}
`;

export const Form = styled.form`
  flex-basis: 100%;

  @media (min-width: 768px) {
    flex-basis: 50%;
  }
`;

export const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;

  ${({ centered }) => centered && `
    display: flex;
    justify-content: center;
  `}
`;

export const Input = styled.input`
  ${FormInput};

  &:active {
    outline: 0;
  }

  &:focus {
    outline: 0;
  }
`;

export const Textarea = styled.textarea`
  ${FormInput};
  border: none;

  &:active {
    outline: 0;
    border: 0;
  }

  &:focus {
    outline: 0;
    border: 0;
  }
`;

export const ButtonSubmit = styled.button`
  display: inline-block;
  background-color: ${c.bluePrimary};
  text-align: center;
  font-family: NunitoSans;
  font-size: 2.2rem;
  font-weight: bold;
  color: #fff;
  padding: 1rem 3rem;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    background-color: ${c.grayDisabled};
    cursor: not-allowed;
  }
`;

export const FormError = styled.div`

`;

import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.h1`
  font-size: 3.5rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 40px;
  margin-left: 20px;
  color: ${c.grayText};
`;

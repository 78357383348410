import { css } from 'styled-components';
import { colors as c } from './colors';

export const BorderedImage = css`
  border: 1px solid ${c.grayBorder};
  padding: 8px;
  background-color: #fff;
`;

export const ShadowedBox = css`
  background-color: ${c.grayBGPrimary};
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
`;
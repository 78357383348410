import React from 'react'
// Style
import {
  Container
} from './PageTitle.styles';

const PageTitle = ({ children }) => {

  return(
    <Container>{children}</Container>
  )
}

export default PageTitle;
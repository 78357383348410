import React from "react"
// Components
import Layout from "../components/layout"
import { Seo } from "../components/Seo"
import { Container1200 } from '../components/Container1200';
import { Formik } from 'formik';
import { PageTitle } from '../components/PageTitle';
// Images
// import nintendo from '../images/other-projects/nintendo.jpg';
// import langton from '../images/other-projects/langtons-ant.jpg';
// Style
import {
  BlurbBox,
  BlurbText,
  ButtonSubmit,
  Form,
  FormGroup,
  Input,
  Intro,
  Social,
  SocialIcon,
  Textarea
} from '../styles/pages-styles/contact.styles';

const Contact = () => {

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  return (
    <Layout>
      <Seo title="Contact Me | DaveJudd.com" />
      <Intro id="intro">
        <Container1200>
          <PageTitle>Send Me a Message</PageTitle>
          <BlurbBox>
            <BlurbText>
              <p>Feel free to reach out through this form or through social media.</p>
              <Social>
                <SocialIcon href="https://github.com/SirDaev">
                  <i className="fab fa-github"></i>
                </SocialIcon>
                <SocialIcon href="https://twitter.com/SirDaev">
                  <i className="fab fa-twitter"></i>
                </SocialIcon>
                <SocialIcon href="https://www.linkedin.com/in/dave-judd-4655a413/">
                  <i className="fab fa-linkedin"></i>
                </SocialIcon>
              </Social>
            </BlurbText>
            <Formik
              initialValues={{
                email: '',
                message: '',
                name: ''
              }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = true;
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                let body = encode({
                  'form-name': 'contact',
                  ...values,
                });
                console.log(body);
                fetch("/", {                                 
                  method: 'POST',
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                  body: body,
                })
                  .then(() => {
                    alert('Success!')
                    setSubmitting(false)
                  })
                  .catch(error => {
                    alert('Error: Please Try Again!')                            
                    setSubmitting(false)
                  })
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form
                  name="contact"
                  method="post"
                  onSubmit={handleSubmit}
                  data-netlify="true"
                  data-netlify-recaptcha="true"
                >
                  <input type="hidden" name="contact" value="contact" />
                  <FormGroup>
                    <Input
                      id="name"
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      err={errors.email && touched.email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Textarea
                      id="message"
                      type="text"
                      name="message"
                      placeholder="Your Message..."
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <div data-netlify-recaptcha="true"></div>
                  </FormGroup> */}
                  <FormGroup>
                    <ButtonSubmit type="submit" disabled={values.email === '' || isSubmitting}>
                      Submit
                    </ButtonSubmit>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </BlurbBox>
        </Container1200>
      </Intro>
    </Layout>
  )
}

export default Contact;
